import axios from "@/plugins/axios";
import qs from "qs";

export const getTenants = async () => {
  const query = qs.stringify({
    populate: {
      name: {
        populate: "*",
      },
      address: {
        populate: "*",
      },
      size: {
        populate: "*",
      },
    },
  });
  const response = await axios.get(`/rent-places?${query}`);
  return response.data.data;
};

export const getPageRent = async () => {
  const response = await axios.get(`/page-rent?`);
  return response.data.data;
};
