<template>
  <div class="tenants">
    <tenantsHero />
    <tenantsOptions :pageRent="pageRent" />
    <tenantsConnections :pageRent="pageRent" />
    <partnersReviews />
    <tenantsBanner />
  </div>
</template>

<script>
import { getPageRent } from "@/api/tenants";

export default {
  name: "Tenants",
  components: {
    TenantsHero: () => import("@/components/pages/tenants/TenantsHero.vue"),
    TenantsOptions: () =>
      import("@/components/pages/tenants/TenantsOptions.vue"),
    TenantsConnections: () =>
      import("@/components/pages/tenants/TenantsConnections.vue"),
    TenantsBanner: () => import("@/components/pages/tenants/TenantsBanner.vue"),
    PartnersReviews: () => import("@/components/common/PartnersReviews.vue"),
  },
  data() {
    return {
      pageRent: {},
    };
  },
  created() {
    getPageRent().then((res) => {
      this.pageRent = res.attributes;
      console.log(this.pageRent);
    });
  },
};
</script>
